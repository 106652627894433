import { Component, Vue } from 'vue-property-decorator'
import { http } from '../plugins/http'
import Application from '../shared/application'

@Component
class Home extends Vue {
  public name: string = ''
  public email: string = ''

  public message: string = ''
  public messageType: string = ''

  public submitNewsletterForm(event: Event) {
    this.resetMessage()

    if (this.name === '' || this.email === '') {
      this.message = 'Vul aub uw naam & e-mailadres in.'
      this.messageType = 'error'
      return
    }

    const target = event.target as HTMLFormElement
    const url = target.getAttribute('action')

    http.post(url, {
      name: this.name,
      email: this.email,
      init: (document.querySelector('#newsletter-init') as HTMLInputElement).value,
    }).then((response) => {
      this.messageType = 'success'
      this.message = 'Uw inschrijving is succesvol verstuurd.'

      setTimeout(() => {
        this.resetMessage()
        this.resetForm()
      }, 7000)
    }).catch((error) => {
      if (! error.response) {
        console.error(error)
        return
      }

      const response = error.response
      const data = response.data
      if (data && data.errors) {
        const keys = Object.keys(data.errors)
        const err = data.errors[keys[0]]

        this.messageType = 'error'
        this.message = err.join(' ')

        setTimeout(() => {
          this.resetMessage()
        }, 7000)
      }
    })
  }

  private resetForm() {
    this.name = ''
    this.email = ''
  }

  private resetMessage() {
    this.message = ''
    this.messageType = ''
  }
}

export default new Application(Home)
